import { makeStyles, Paper, Tooltip } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { WarningRounded } from "@material-ui/icons";
import React from "react";
import { clsx } from "../../clsx";
import { BoldNumberDisplay } from "./BoldNumberDisplay";

export interface SideBySideProps {
  totalUnits: number;
  totalTimeSheet: number;
  containerProps?: CSSProperties;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  isLeft: {},
  isRight: {},
  column: {
    padding: "7px",
    display: "inherit",
    flexDirection: "column",
  },
  centeringBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export function SideBySide(props: SideBySideProps): JSX.Element {
  const { totalTimeSheet, totalUnits, containerProps } = props;

  const match = React.useMemo(
    () => totalUnits.toFixed(2) === totalTimeSheet.toFixed(2),
    [totalTimeSheet, totalUnits]
  );

  const styles = useStyles();
  return (
    <Paper
      className={clsx(styles.container)}
      style={containerProps}
      elevation={3}
    >
      <div className={clsx(styles.column, styles.isLeft)}>
        <BoldNumberDisplay
          label={"Total Timesheet"}
          num={totalTimeSheet}
          unit={"Hours"}
        />
      </div>
      {!match && (
        <div className={styles.centeringBox}>
          <Tooltip title={"These values do not match!"}>
            <WarningRounded />
          </Tooltip>
        </div>
      )}
      <div className={clsx(styles.column, styles.isRight)}>
        <BoldNumberDisplay
          label={"Total Units"}
          num={totalUnits}
          unit={"Hours"}
        />
      </div>
    </Paper>
  );
}
