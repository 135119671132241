import React, { Fragment } from "react";
import { DateTimePicker } from "@material-ui/pickers";
import { DateTime } from "luxon";

export interface BasicDateTimePickerProps {
  label?: string;
  value: DateTime;
  onChange: (newDate: DateTime) => void;
}

function BasicDateTimePicker(props: BasicDateTimePickerProps): JSX.Element {
  const { label, value, onChange } = props;

  return (
    <Fragment>
      <DateTimePicker
        label={label}
        inputVariant="outlined"
        value={value}
        onChange={(date: DateTime | null) => {
          if (date) onChange(date);
        }}
        format={"hh:mm aa M dd, yyyy"}
      />
    </Fragment>
  );
}

export default BasicDateTimePicker;
