import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { ModalProps } from "../common/interfaces";
import { ConfirmDenyButtonAction } from "./Input/ConfirmDenyButtonAction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    inlineContainer: {
      display: "inline",
    },
    centeringContainer: {
      display: "block",
      margin: "auto",
      padding: theme.spacing(2, 4, 3),
    },
    defaultMessage: {
      fontSize: 18,
      textAlign: "center",
    },
    button: {
      margin: "10px",
    },
  })
);

interface IConfirmationModalProps extends ModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message?: string | JSX.Element;
  confirmText?: string;
  cancelText?: string;
}

export default function ConfirmationModal(
  props: IConfirmationModalProps
): JSX.Element {
  const {
    open,
    setOpen,
    onConfirm,
    onCancel,
    message,
    confirmText,
    cancelText,
  } = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.inlineContainer}>
              <div className={classes.centeringContainer}>
                {message ?? (
                  <div className={classes.defaultMessage}>{"Confirm"}</div>
                )}
              </div>
              <ConfirmDenyButtonAction
                confirmText={confirmText ?? "Confirm"}
                denyText={cancelText ?? "Cancel"}
                confirmAction={onConfirm}
                denyAction={onCancel}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
