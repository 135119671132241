import React from "react";
import ClassificationEditor from "../components/ClassificationEditor";
import BasicView from "./BasicView";

export default function ServicesView(): JSX.Element {
  return (
    <BasicView>
      <ClassificationEditor />
    </BasicView>
  );
}
