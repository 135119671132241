import {
  Box,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";
import ConfirmationModal from "../components/ConfirmationModal";
import DropdownSelect from "../components/Input/DropdownSelect";
import WorkItemTable from "../components/WorkItemTable";
import { SchoolContext } from "../context/SchoolContext";
import { WorkItemContext } from "../context/WorkItemContext";
import BasicView from "./BasicView";
import clsx from "clsx";
import FileSaver from "file-saver";
import { SettingsContext } from "../context/SettingsContext";

import { IWorkItem, DropdownSelectorOption } from "../common/interfaces";
import { TherapistContext } from "../context/TherapistContext";
import Toaster, { ToastType } from "../common/Toaster";
import { addLeadingZeros } from "../common/helpers";
import { buildWorkItemCSV, downloadCSV, getSchoolById } from "../common/Utils";
import { WorkItemSummaryPane } from "../components/Display/WorkItemSummaryPane";
import { BillingActions } from "../components/Input/BillingActions";
import { API } from "../context/APIContext";
import { createLogger } from "../components/Logging/Logging";
import LoadingSpinner from "../components/LoadingSpinner";
import { InvoiceContext } from "../context/InvoiceContext";
import { DateTime } from "luxon";
import { formatISO } from "../common/DateUtils";
import { FullScreenModal } from "../components/Display/FullScreenModal";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { SimpleDatePicker } from "../components/Input/SimpleDatePicker";
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  dimmed: {
    color: theme.palette.text.disabled,
  },
  centeringColumn: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  button: {
    marginTop: "10px",
  },
  datePicker: {
    maxWidth: "165px",
  },
  someMargin: {
    margin: "8px",
  },
  inlineContainer: {
    display: "inline",
  },
  tableArea: {
    paddingTop: "0px",
  },
  flexContainer: {
    display: "flex",
  },
  left: {
    float: "left",
  },
  right: {
    marginLeft: "auto",
  },
  filterContainer: {
    display: "flex",
  },
  leftColumn: {
    flex: "75%",
    paddingBottom: "5px",
  },
  rightColumn: {
    flex: "25%",
  },
  fixedWidth: {},
  spinnerBack: {
    width: "80px",
    height: "80px",
    borderRadius: "100%",
    backgroundColor: theme.palette.primary.main,
  },
}));

const logger = createLogger("BillingView");

export default function BillingView(): JSX.Element {
  const schoolContext = React.useContext(SchoolContext);
  const workItemContext = React.useContext(WorkItemContext);
  const settingsContext = React.useContext(SettingsContext);
  const therapistContext = React.useContext(TherapistContext);
  const invoiceContext = React.useContext(InvoiceContext);
  const api = React.useContext(API);

  const {
    filteredWorkItems,
    setSchool,
    setStartDate,
    setEndDate,
    setClassId,
    setShowInvoiced,
    showInvoiced,
    school,
    start,
    end,
    classId,
    timeRanges,
  } = workItemContext;
  const [selected, setSelected] = React.useState<IWorkItem[]>([]);
  const [therapist] = React.useState<string>("All");
  const schoolDropdownOptions = schoolContext.schools.map((school) => ({
    label: `${school.name} ${school.invoicePrefix}`,
    value: school.id,
  }));

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    React.useState<boolean>(false);
  const [invoiceConfirmationModalOpen, setInvoiceConfirmationModalOpen] =
    React.useState<boolean>(false);
  const [invoicePreviewModalOpen, setInvoicePreviewModalOpen] =
    React.useState<boolean>(false);
  const [invoiceNumber, setInvoiceNumber] = React.useState<string>("");
  const [invoiceDate, setInvoiceDate] = React.useState<DateTime>(
    DateTime.local()
  );
  const [invoiceNumberError, setInvoiceNumberError] =
    React.useState<boolean>(false);
  const [pdfLoading, setPDFLoading] = React.useState<boolean>(false);
  const [invoicePreview, setInvoicePreview] = React.useState<
    string | undefined
  >(undefined);
  const classes = useStyles();

  const handleChange = React.useCallback(
    (e: DropdownSelectorOption) => {
      setSchool(e.value);
    },
    [setSchool]
  );

  const onDelete = React.useCallback(() => {
    setDeleteConfirmationModalOpen(true);
  }, []);

  const onDeleteModalConfirm = React.useCallback(() => {
    api.deleteWorkItems(selected.map((i) => i._id));
    setDeleteConfirmationModalOpen(false);
    setSelected([]);
  }, [api, selected]);

  const onDeleteModalCancel = React.useCallback(() => {
    setDeleteConfirmationModalOpen(false);
  }, []);

  const onInvoiceModalCancel = React.useCallback(() => {
    setPDFLoading(false);
    setInvoiceConfirmationModalOpen(false);
  }, []);

  const openInvoiceModal = () => {
    const thisSchool = schoolContext.schools.find((sch) => sch.id === school);
    let nextInvoiceId = "0";

    if (!thisSchool) {
      Toaster(`Counld not find school with id ${school}`, ToastType.error);
      logger.error(`Counld not find school with id ${school}`, {
        id: school,
        schools: schoolContext.schools,
      });
      return;
    }
    setInvoiceConfirmationModalOpen(true);

    api.getNextInvoiceId({ prefix: thisSchool.invoicePrefix }).then(
      async (res) =>
        await res.json().then(async (i) => {
          if (res.status === 200) {
            nextInvoiceId = addLeadingZeros(i.id, 0);
            setInvoiceNumber(thisSchool?.invoicePrefix + "_" + nextInvoiceId);
          }
        })
    );
  };

  const downloadInvoice = React.useCallback(
    async (dryRun: boolean) => {
      setPDFLoading(true);
      const schoolPrefix = schoolContext.schools.find(
        (s) => s.id === school
      )?.invoicePrefix;

      if (!schoolPrefix) {
        Toaster(`Could not find school with id ${school}`, ToastType.error);
        return;
      }

      const res = await api.getInvoice({
        schoolId: school,
        classId: classId,
        startDate: formatISO(start),
        endDate: formatISO(end),
        invoiceId: invoiceNumber,
        isDryRun: dryRun.toString(),
        invoiceDate: invoiceDate.toFormat("MM/dd/yyyy"),
      });
      const data = await res.json();
      if (res.status === 200) {
        invoiceContext.makeInvoicesDirty();
        const encodedPDF = data.blob;
        if (dryRun) {
          setInvoicePreview(encodedPDF);
          return;
        }
        FileSaver.saveAs(
          "data:application/pdf;base64," + encodedPDF,
          `invoice_${invoiceNumber}.pdf`
        );
        Toaster(`Invoice ${invoiceNumber} downloaded`, ToastType.good);
        const incrementResponse = await api.incrementInvoiceId({
          prefix: schoolPrefix,
        });

        if (incrementResponse.ok) {
          Toaster(`${schoolPrefix} Sequence Incrememented`, ToastType.info);
        } else {
          Toaster(
            `Failed to Increment ${schoolPrefix} Sequence`,
            ToastType.error
          );
          logger.error(`Failed to Increment ${schoolPrefix} Sequence`, {
            status: incrementResponse.status,
            message: incrementResponse.statusText,
            body: await incrementResponse.text(),
          });
        }
      } else {
        Toaster(
          `Failed to download invoice ${invoiceNumber}: ${data.message}`,
          ToastType.error
        );
        logger.error(
          `ERROR Code ${res.status}: ${res.statusText} => ${data.message}`,
          {
            res,
            data,
          }
        );
      }
      setPDFLoading(false);
    },
    [
      api,
      end,
      start,
      school,
      invoiceDate,
      invoiceNumber,
      classId,
      invoiceContext,
      schoolContext.schools,
    ]
  );

  const invoiceConfirmationMessageElement = React.useMemo(
    () =>
      pdfLoading || invoiceNumber === "" ? (
        <div style={{ marginLeft: "30px" }}>
          <div className={classes.spinnerBack}>
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div>
          <Typography variant="h6">Generate Invoice</Typography>
          <br></br>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TextField
              label={"Invoice Number"}
              variant="outlined"
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setInvoiceNumber(e.target.value);
                setInvoiceNumberError(false);
              }}
              value={invoiceNumber}
              error={invoiceNumberError}
            />
            <div style={{ width: "10px" }}></div>
            <SimpleDatePicker
              margin="none"
              label={"Invoice Date"}
              value={invoiceDate}
              onChange={(incoming: MaterialUiPickersDate) => {
                if (!incoming) {
                  logger.error("Incoming date of invoiceDate is undefined!");
                  return;
                }
                setInvoiceDate(incoming);
              }}
            />
          </div>
        </div>
      ),
    [
      pdfLoading,
      invoiceNumber,
      classes.spinnerBack,
      invoiceNumberError,
      invoiceDate,
    ]
  );

  const onInvoicePreviewDownload = React.useCallback(() => {
    downloadInvoice(false);
    setInvoicePreview(undefined);
    setPDFLoading(false);
    setInvoiceNumber("");
    setInvoicePreviewModalOpen(false);
  }, [downloadInvoice]);

  const onInvoicePreviewClose = React.useCallback(() => {
    setPDFLoading(false);
    setInvoiceNumber("");
    setInvoicePreviewModalOpen(false);
  }, []);

  const invoicePreviewElement = React.useMemo(() => {
    if (school === "All") return <></>;
    if (!invoicePreviewModalOpen) return <></>;
    if (!invoicePreview) {
      downloadInvoice(true);
      return <></>;
    }
    return (
      <FullScreenModal
        onConfirm={onInvoicePreviewDownload}
        onClose={onInvoicePreviewClose}
      >
        <embed
          style={{ width: "100%", height: "100%" }}
          src={`data:application/pdf;base64,${invoicePreview}`}
        />
      </FullScreenModal>
    );
  }, [
    downloadInvoice,
    invoicePreview,
    invoicePreviewModalOpen,
    onInvoicePreviewClose,
    onInvoicePreviewDownload,
    school,
  ]);

  const onInvoiceModalConfirm = React.useCallback(async () => {
    setInvoiceNumberError(false);
    if (invoiceNumber.length === 0) {
      setInvoiceNumberError(true);
      Toaster("Invoice Number Required", ToastType.error);
      return;
    }
    let isUnique = false;
    await api.getIsInvoiceNumberUnique({ invoiceNumber: invoiceNumber }).then(
      async (res) =>
        await res.json().then(async (unique) => {
          if (res.status === 200) {
            isUnique = unique;
          } else {
            logger.error(
              `ERROR Code ${res.status}: ${res.statusText} => ${unique.message}`,
              {
                res,
                unique,
              }
            );
          }
        })
    );
    if (!isUnique) {
      setInvoiceNumberError(true);
      Toaster("Invoice Number needs to be unique", ToastType.error);
      return;
    }

    setInvoiceConfirmationModalOpen(false);
    setInvoicePreviewModalOpen(true);
  }, [invoiceNumber, api]);
  const _school = React.useMemo(() => {
    if (school === "All")
      return {
        name: "All",
        id: "All",
        invoicePrefix: "",
      };
    return getSchoolById(school, schoolContext.schools);
  }, [school, schoolContext.schools]);

  return (
    <BasicView>
      <WorkItemSummaryPane
        selectedSchool={school}
        selectedTherapist={therapist}
        schools={schoolContext.schools}
        workItems={filteredWorkItems}
        start={start}
        end={end}
      />
      <BillingActions
        invoiceDisabled={
          school === "All" ||
          classId === "All" ||
          filteredWorkItems.length === 0
        }
        invoiceAction={openInvoiceModal}
        csvAction={() =>
          downloadCSV(
            buildWorkItemCSV(
              filteredWorkItems,
              schoolContext.schools,
              therapistContext.therapists,
              settingsContext.classes
            ),
            `WorkItem_Report_${DateTime.local().toLocaleString()}`
          )
        }
        hideCSV
      />
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div>
            <Box className={classes.filterContainer}>
              <div className={clsx(classes.leftColumn)}>
                <DropdownSelect
                  label={"School"}
                  value={{
                    label: `${_school?.name ?? ""} ${
                      _school?.invoicePrefix ?? ""
                    }`,
                    value: school,
                  }}
                  handleChange={(ns) => {
                    if (ns) handleChange(ns);
                  }}
                  options={schoolDropdownOptions}
                  includeAll
                  isClearable
                />
                <DropdownSelect
                  value={{
                    label: settingsContext.classes[classId]?.name ?? "All",
                    value: classId,
                  }}
                  label="Related Service"
                  options={Object.entries(settingsContext.classes).map(
                    ([id, service]) => ({ label: service.name, value: id })
                  )}
                  handleChange={(e: DropdownSelectorOption | null) => {
                    if (e) setClassId(e.value);
                  }}
                  includeAll
                  isClearable
                />
              </div>
            </Box>
            <div className={classes.tableArea}>
              <WorkItemTable
                rows={filteredWorkItems}
                selected={selected}
                setSelected={setSelected}
                onDelete={onDelete}
                columnsToHide={[]}
                start={start}
                end={end}
                showInvoicedWorkItems={showInvoiced}
                timeRanges={timeRanges}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setShowInvoicedWorkItems={setShowInvoiced}
                listStudents
              />
            </div>
            <ConfirmationModal
              open={deleteConfirmationModalOpen}
              setOpen={setDeleteConfirmationModalOpen}
              onConfirm={onDeleteModalConfirm}
              onCancel={onDeleteModalCancel}
            />
            <ConfirmationModal
              open={invoiceConfirmationModalOpen}
              setOpen={setInvoiceConfirmationModalOpen}
              onConfirm={onInvoiceModalConfirm}
              onCancel={onInvoiceModalCancel}
              message={invoiceConfirmationMessageElement}
              confirmText={"Create Draft"}
            />
            {invoicePreviewModalOpen && invoicePreviewElement}
          </div>
        </Paper>
      </Grid>
    </BasicView>
  );
}
