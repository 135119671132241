import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ISchool, IWorkItem } from "../../common/interfaces";
import { clsx } from "clsx";
import { DateTime } from "luxon";
export interface WorkItemSummaryPaneProps {
  selectedSchool: string;
  selectedTherapist: string;
  schools: ISchool[];
  workItems: IWorkItem[];
  start: DateTime;
  end: DateTime;
}

const useStyles = makeStyles({
  paper: {
    padding: "20px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  totals: {
    padding: "auto",
  },
  dimmed: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

export function WorkItemSummaryPane(
  props: WorkItemSummaryPaneProps
): JSX.Element {
  const { selectedSchool, selectedTherapist, schools, workItems, start, end } =
    props;
  const styles = useStyles();

  const totalUnits =
    workItems.length > 0
      ? workItems
          .map((workItem) => workItem.units)
          .reduce((prev, current) => prev + current, 0)
      : 0;
  const totalHours = totalUnits / 4;

  return (
    <Grid item xs={6}>
      <Paper className={clsx(styles.paper, styles.totals)}>
        <Typography variant="h5" component="h6">
          Total for
          {selectedSchool === "All"
            ? ` All Districts (${schools.length})`
            : " " + schools.find((s) => s.id === selectedSchool)?.name ?? ""}
          {selectedTherapist === "All" ? "" : ` > ${selectedTherapist}`}
        </Typography>
        <Typography variant="body2" className={styles.dimmed}>
          {start.toFormat("EEE MMM d yyyy")} to {end.toFormat("EEE MMM d yyyy")}
        </Typography>
        <Typography variant="subtitle1">Units: {totalUnits} units</Typography>
        <Typography variant="subtitle1">Hours: {totalHours} hours</Typography>
      </Paper>
    </Grid>
  );
}
