import React from "react";
import Loading, { LoadingProps } from "../loading";

interface Props extends Partial<LoadingProps> {
  message?: string;
}

export default function LoadingSplash(props: Props): JSX.Element {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "absolute",
        left: "0",
        top: "0",
      }}
    >
      <Loading message={props.message} />
    </div>
  );
}
