import { PropTypes } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { DateTime } from "luxon";
import React from "react";

export interface SimpleDatePickerProps {
  label: string;
  value: ParsableDate;
  onChange: (date: DateTime | null, value?: string | null | undefined) => void;
  className?: string;
  margin?: PropTypes.Margin;
}

export function SimpleDatePicker(props: SimpleDatePickerProps): JSX.Element {
  const { label, value, onChange, className, margin } = props;
  return (
    <KeyboardDatePicker
      disableToolbar
      variant="inline"
      format="MM/dd/yyyy"
      margin={margin ?? "normal"}
      id={`id-${label}`}
      label={label}
      value={value}
      onChange={onChange}
      KeyboardButtonProps={{
        "aria-label": "change DateTime",
      }}
      inputVariant="outlined"
      className={className}
      autoOk
    />
  );
}
