import React from "react";
import { ITimesheetRecord } from "../common/interfaces";
import { useIsMounted } from "../common/useIsMounted";
import { isContentJSON } from "../common/Utils";
import { createLogger } from "../components/Logging/Logging";
import { API } from "./APIContext";
import { DateTime } from "luxon";

interface ITimesheetContext {
  triggerRefresh: () => void;
  timesheetRecords: ITimesheetRecord[];
  isLoading: boolean;
  deadline: DateTime;
}
interface ITimesheetProps {}

const defaultState: ITimesheetContext = {
  timesheetRecords: [],
  triggerRefresh: () => null,
  isLoading: true,
  deadline: DateTime.local(),
};
export const TimesheetContext =
  React.createContext<ITimesheetContext>(defaultState);

const logger = createLogger("TimesheetContext");

export default function TimesheetContextProvider(
  props: React.PropsWithChildren<ITimesheetProps>
): JSX.Element {
  const isMounted = useIsMounted();
  const api = React.useContext(API);

  const [refreshTrigger, setRefreshTrigger] = React.useState<number>(0);
  const triggerRefresh = () => setRefreshTrigger((prev) => prev + 1);
  const [state, setState] = React.useState<ITimesheetContext>({
    ...defaultState,
    triggerRefresh,
  });

  React.useEffect(() => {
    try {
      api
        .getTimesheets()
        .then(async (res) => {
          if (isContentJSON(res)) {
            await res.json().then((json) => {
              if (res.status === 200) {
                if (isMounted.current)
                  setState((prev) => ({
                    ...prev,
                    timesheetRecords: json as ITimesheetRecord[],
                    isLoading: false,
                  }));
              } else {
                logger.error(
                  `ERROR Code ${res.status}: ${res.statusText} => ${json.message}`,
                  {
                    res,
                    json,
                  }
                );
              }
            });
          }
        })
        .catch((e) => {
          logger.error(e);
        });
      api.getDeadline().then(async (res) => {
        if (isContentJSON(res)) {
          const data = await res.json();
          if (data.deadline) {
            const deadline = DateTime.fromISO(data.deadline);
            setState((prev) => ({
              ...prev,
              deadline: deadline,
            }));
          }
        }
      });
    } catch (e) {
      logger.error("unknown error", e);
    }
  }, [api, isMounted, refreshTrigger]);

  React.useEffect(() => {
    logger.info(`loaded = ${!state.isLoading}`);
  }, [state.isLoading]);

  return (
    <TimesheetContext.Provider value={state}>
      {props.children}
    </TimesheetContext.Provider>
  );
}
