import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { createPortal } from "react-dom";

export interface FullScreenModalProps {
  children: React.ReactElement;
  onClose: () => void;
  onConfirm: () => void;
  menu?: React.ReactElement;
}

const useStyles = makeStyles({
  fullscreen: {
    height: "100vh",
    width: "100vw",
    zIndex: 9999,
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "white",
  },
  menu: {
    width: "100%",
    backgroundColor: "black",
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    justifyContent: "space-between",
  },
});

export function FullScreenModal(props: FullScreenModalProps): JSX.Element {
  const styles = useStyles();
  const menu = React.useMemo(
    function () {
      if (props.menu) return props.menu;
      return (
        <div className={clsx(styles.menu)}>
          <Button variant="contained" onClick={props.onConfirm}>
            Finalize Draft
          </Button>
          <Button color="primary" variant="contained" onClick={props.onClose}>
            Close
          </Button>
        </div>
      );
    },
    [props, styles.menu]
  );

  const element = React.useMemo(() => {
    return (
      <div className={clsx(styles.fullscreen)}>
        {menu}
        {props.children}
      </div>
    );
  }, [menu, props.children, styles.fullscreen]);
  return createPortal(element, document.body);
}
