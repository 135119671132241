import { Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import { makeStyles } from "@material-ui/styles";
import React from "react";

export interface BoldNumberDisplayProps {
  label: string;
  num: number;
  unit?: string;
  leftPad?: number;
  pad?: string;
  precision?: number;
  variant?: Variant;
  compact?: boolean;
}

const useStyles = makeStyles({
  bottomBorder: {
    borderBottom: "2px solid  rgba(0, 0, 0, 0.23)",
    borderBottomLeftRadius: "2px",
  },
  border: {
    border: "2px solid  rgba(0, 0, 0, 0.23)",
    borderRadius: "5px",
  },
  numberContainer: {
    marginTop: "5px",
    padding: "2px",

    fontFamily: "monospace",
  },
  compactNumberContainer: {
    marginBottom: "-10px",
    fontFamily: "monospace",
  },
});

const DEFAULT_PRECISION = 2;
const DEFAULT_LEFT_PAD = 2;

export function BoldNumberDisplay(props: BoldNumberDisplayProps): JSX.Element {
  const { label, num, unit, leftPad, pad, precision, variant } = props;
  const styles = useStyles();

  const formatTime = React.useCallback(
    (time: number) => {
      const _precision = precision ?? DEFAULT_PRECISION;
      const _leftPadding = leftPad ?? DEFAULT_LEFT_PAD;
      return time
        .toFixed(_precision)
        .padStart(_precision + _leftPadding + 1, pad ?? "0");
    },
    [leftPad, pad, precision]
  );

  return (
    <>
      {!props.compact && (
        <Typography noWrap color={"textSecondary"} align={"center"}>
          {`${label}${unit ? ` (${unit})` : ""}`}
        </Typography>
      )}
      <div className={styles.border}>
        <Typography
          noWrap
          color={"primary"}
          variant={variant ?? "h2"}
          align={"center"}
          className={
            props.compact
              ? styles.compactNumberContainer
              : styles.numberContainer
          }
        >
          {formatTime(num)}
        </Typography>
        {props.compact && (
          <Typography
            noWrap
            color={"textSecondary"}
            align={"center"}
            variant="subtitle2"
          >
            {`${label}${unit ? ` (${unit})` : ""}`}
          </Typography>
        )}
      </div>
    </>
  );
}
