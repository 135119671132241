import { createTheme, Theme, ThemeProvider } from "@material-ui/core";
import { blue, red, purple } from "@material-ui/core/colors";
import React from "react";

const defaultTheme = createTheme({
  palette: {
    primary: blue,
    secondary: red,
  },
});

interface AppTheme {
  theme: Theme;
  mode: "dark" | "light";
  update: (theme: Theme) => void;
  toggleMode: () => void;
}

export const AppThemeContext = React.createContext<AppTheme>({
  theme: defaultTheme,
  mode: "light",
  update: () => null,
  toggleMode: () => null,
});

export function AppThemeContextProvider(props: {
  children: JSX.Element;
}): JSX.Element {
  const [theme, setTheme] = React.useState<Theme>(defaultTheme);
  const [mode, setMode] = React.useState<"dark" | "light">("light");

  const update = React.useCallback((theme: Theme) => {
    setTheme(theme);
  }, []);

  const toggleMode = React.useCallback(() => {
    if (mode === "light") setMode("dark");
    else setMode("light");
    setTheme((prev) =>
      createTheme({
        ...prev,
        palette: {
          ...prev.palette,
          type: mode,
        },
      })
    );
  }, [mode]);

  React.useEffect(() => {
    const backendUrl = process.env.REACT_APP_SERVER_URL;
    if (backendUrl && !backendUrl.includes("production")) {
      setTheme((prev) =>
        createTheme({
          ...prev,
          palette: {
            primary: purple,
            secondary: red,
          },
        })
      );
    }
  }, []);

  const appTheme = React.useMemo(() => {
    return {
      theme,
      mode,
      update,
      toggleMode,
    };
  }, [theme, mode, update, toggleMode]);

  return (
    <AppThemeContext.Provider value={appTheme}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </AppThemeContext.Provider>
  );
}
