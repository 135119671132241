import { IconButton } from "@material-ui/core";
import React from "react";
import { VisibilityIcon, VisibilityOffIcon } from "../../icons";

interface EyeProps {
  visible: boolean;
  toggle: () => void;
}

export default function Eye(props: EyeProps): JSX.Element {
  const comp = React.useMemo(() => {
    return (
      <IconButton onClick={props.toggle}>
        {props.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    );
  }, [props.toggle, props.visible]);
  return comp;
}
