import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { ISchool } from "../common/interfaces";
import { IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { SchoolContext } from "../context/SchoolContext";
import SortableTable from "./Display/Table/SortableTable";
import { Action, OverrideScript, WithIndex } from "../common/Script";
import { AddIcon } from "../icons";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  centering: {
    display: "block",
    margin: "auto",
    padding: "auto",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  addButton: {
    marginLeft: "auto",
    marginRight: "20px",
  },
});

interface ISchoolTableProps {
  onAdd: () => void;
  onEdit: (school: ISchool) => void;
  onDelete: (school: ISchool) => void;
}

export default function SchoolTable(props: ISchoolTableProps): JSX.Element {
  const { schools } = React.useContext(SchoolContext);
  const classes = useStyles();

  const startEdit = React.useCallback<Action<ISchool>>(
    (type: WithIndex<ISchool>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { index, ...rest } = type;
      props.onEdit(rest as ISchool);
    },
    [props]
  );

  const startDelete = React.useCallback<Action<ISchool>>(
    (type: WithIndex<ISchool>) => {
      props.onDelete(type);
    },
    [props]
  );

  const script: OverrideScript<ISchool> = React.useMemo(
    () => [
      {
        id: "name",
        text: "Name",
        getField: (s: ISchool) => s.name,
      },
      {
        id: "address",
        text: "Address",
        getField: (s: ISchool) => s.address,
      },
      {
        id: "prefix",
        text: "Invoice Prefix",
        getField: (s: ISchool) => s.invoicePrefix,
      },
      {
        id: "students",
        text: "Students",
        getField: (s: ISchool) => s.students.length,
      },
      {
        isAction: true,
        id: "action",
        text: "Action",
        align: "right",
        action: [startEdit, startDelete],
        actionIcon: [<EditIcon key="edit" />, <DeleteIcon key="delete" />],
      },
    ],
    [startDelete, startEdit]
  );

  const toolbar = React.useMemo(() => {
    return (
      <>
        <div className={classes.flexRow}>
          <div style={{ textAlign: "center", margin: "auto 0" }}>
            <Typography variant="h5">Districts</Typography>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <IconButton
              className={classes.addButton}
              color="primary"
              onClick={() => props.onAdd()}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>
      </>
    );
  }, [classes.addButton, classes.flexRow, props]);

  return (
    <Paper className={classes.centering}>
      <SortableTable
        label={"Districts"}
        script={script}
        rows={schools}
        altToolbar={toolbar}
        hidePagination
        defaultSortKey="name"
      />
    </Paper>
  );
}
